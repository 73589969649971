import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { toast } from "@/hooks/use-toast"
import { getCurrentUser, setCurrentUser } from '@/utils/session'

interface User {
  userid?: string
  access_token?: string
  refresh_token?: string
}

interface ApiResponse<T = unknown> {
  data: T;
  code?: number;
  message?: string;
}

interface CustomRequestConfig extends AxiosRequestConfig {
  errorMessage?: string;
}

const api = axios.create({
  baseURL: '/kinet3',
})

// 添加请求拦截器来自动添加 token
api.interceptors.request.use(
  async (config) => {
    const user = getCurrentUser()
    if (user?.access_token) {
      config.headers.Authorization = `Bearer ${user.access_token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

let isRefreshing = false
let refreshSubscribers: ((token: string) => void)[] = []

// 添加一个订阅者到队列中
const subscribeTokenRefresh = (cb: (token: string) => void) => {
  refreshSubscribers.push(cb)
}

// 执行所有订阅者
const onRrefreshed = (token: string) => {
  refreshSubscribers.map(cb => cb(token))
  refreshSubscribers = []
}

const refreshToken = async (): Promise<string> => {
  const current: User = getCurrentUser() ?? {}
  
  if (!current.userid || !current.refresh_token) {
    toast({ variant: "destructive", title: "登录已过期，请重新登录" })
    window.location.href = '/login'
    throw new Error('No refresh token')
  }

  try {
    const response: AxiosResponse<User> = await axios.post('/kinet3/user/refresh_token', {
      user_id: current.userid,
      refresh_token: current.refresh_token,
    })
    const user = response.data
    if (user.access_token) {
      setCurrentUser({ access_token: user.access_token })
      if (user.refresh_token) {
        setCurrentUser({ refresh_token: user.refresh_token })
      }
      return user.access_token
    }
    throw new Error('No access token in response')
  } catch (error) {
    console.error('Failed to refresh token:', error)
    window.location.href = '/login'
    throw error
  }
}

api.interceptors.response.use(
  async (response: AxiosResponse): Promise<any> => response.data,
  async (error: any) => {
    const { response, config }: { response: AxiosResponse<ApiResponse<unknown>>, config: CustomRequestConfig } = error
    const originalRequest = config

    // 如果是刷新token的请求失败，直接跳转登录
    if (config.url === '/refresh_token') {
      toast({ variant: "destructive", title: "操作超时，请重新登录" })
      window.location.href = '/login'
      return Promise.reject(error)
    }

    if (config.url === '/logout') {
      return Promise.reject(error)
    }

    // token 过期
    if (response?.status === 510 && response.data.code === 205) {
      if (!isRefreshing) {
        isRefreshing = true
        
        try {
          const newToken = await refreshToken()
          isRefreshing = false
          onRrefreshed(newToken)
          
          // 重试当前请求
          originalRequest.headers = originalRequest.headers || {};
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return api.request(originalRequest);
        } catch (err) {
          isRefreshing = false
          return Promise.reject(err)
        }
      } else {
        // 返回一个promise，等待token刷新
        return new Promise((resolve) => {
          subscribeTokenRefresh((token: string) => {
            originalRequest.headers = originalRequest.headers || {};
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(api.request(originalRequest));
          })
        })
      }
    } else if (response?.status === 510) {
      if (config.errorMessage) {
        toast({ variant: "destructive", title: config.errorMessage })
      } else {
        toast({ variant: "destructive", title: response.data.message })
      }
      return Promise.reject(error)
    } else {
      toast({ variant: "destructive", title: "接口请求失败" })
      return Promise.reject(error)
    }
  }
)

export default api
