'use client';

import Link from "next/link"
import { useRouter } from 'next/navigation';
import { useRequest } from 'ahooks';
import { useState } from 'react';

import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import api from "@/utils/api";
import { setCurrentUser } from "@/utils/session";

interface LoginResponse {
  user_id: string;
  username: string;
  nickname: string;
  access_token: string;
  refresh_token: string;
  organizations: string[];
}

export function LoginForm() {
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isNavigating, setIsNavigating] = useState(false);

  const { loading, run } = useRequest(async (values) => {
    try {
      const form = new FormData();
      form.append('username', values.email);
      form.append('password', values.password);
      
      const response_data = await api.post('/user/login', form);
      const data = response_data as unknown as LoginResponse;
      
      setCurrentUser({
        userid: data.user_id,
        username: data.username,
        nickname: data.nickname,
        access_token: data.access_token,
        refresh_token: data.refresh_token,
        organizations: data.organizations,
      });
      
      setIsNavigating(true);
      await router.push('/platform/tasks');
    } catch (error) {
      console.error('Login error:', error);
      setIsNavigating(false);
      throw error;
    }
  }, { manual: true });

  const isDisabled = loading || isNavigating;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    run({ email, password });
  };

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Login</CardTitle>
        <CardDescription>
          Enter your email below to login to your account
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="grid gap-4">
          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="m@example.com"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isDisabled}
            />
          </div>
          <div className="grid gap-2">
            <div className="flex items-center">
              <Label htmlFor="password">Password</Label>
              <Link 
                href="#" 
                className={`ml-auto inline-block text-sm underline ${
                  isDisabled ? 'pointer-events-none opacity-50' : ''
                }`}
              >
                Forgot your password?
              </Link>
            </div>
            <Input 
              id="password" 
              type="password" 
              required 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isDisabled}
            />
          </div>
          <div></div>
          <Button 
            type="submit" 
            className="w-full bg-amber-500 hover:bg-amber-600"
            disabled={isDisabled}
          >
            {isDisabled ? 'Logging...' : 'Login'}
          </Button>
        </form>
        <div className="mt-4 text-center text-sm">
          Don&apos;t have an account?{" "}
          <Link 
            href="#" 
            className={`underline ${
              isDisabled ? 'pointer-events-none opacity-50' : ''
            }`}
          >
            Sign up
          </Link>
        </div>
      </CardContent>
    </Card>
  )
}
